import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { TestComponent } from './test/test.component';
import { LogoutComponent } from './logout/logout.component';

//import { SocialLoginComponent } from './social-login/social-login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';



const routes: Routes = [
    { path: 'companyadmin', loadChildren: () => import('./layout-companyadmin/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
    { path: 'user', loadChildren: () => import('./layout-user/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
    { path: 'superadmin', loadChildren: () => import('./layout-superadmin/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
    { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    //{ path: '',  component: LandingPageComponent },
    
   { path: 'test',  component: TestComponent },
   { path: 'homepage',  component: HomePageComponent },
   { path: 'landingpage',  component: LandingPageComponent },
 { path: 'logout',  component: LogoutComponent },
  // { path: 'sociallogin',  component: SocialLoginComponent },
    
    

    
    { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
    { path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },
    { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },

    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
