import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { TestComponent } from './test/test.component';
import { HttpModule } from '@angular/http';


import {NgxPaginationModule} from 'ngx-pagination';
import { LogoutComponent } from './logout/logout.component';
//import { SocialLoginComponent } from './social-login/social-login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

//import { Http, Response, Headers, RequestOptions } from '@angular/http';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxPaginationModule,
        LanguageTranslationModule,
        AppRoutingModule,HttpModule
    ],
    declarations: [AppComponent, TestComponent, LogoutComponent ,
    //    SocialLoginComponent,
     HomePageComponent,
    LandingPageComponent
    ],
    providers: [AuthGuard],
    bootstrap: [AppComponent]
})

export class AppModule {}
